<template>
  <div class="home">
    <!-- header -->
    <Header></Header>

    <div class="container">
      <!-- 左侧导航栏 -->
      <side-bar></side-bar>

      <div class="content">
        <!-- 客户端banner -->
        <div id="videoBox" class="video-box">
          <div class="client-box">
            <div class="client-banner">
              <div class="client-content">
                <h2>欢迎来到元宇宙DV101</h2>
                <p>客户端版本：2.4.3.1</p>
                <p>版本大小：3.47 GB</p>
                <p>开放状态：可供下载</p>
                <div class="client-download">
                  <a class="download-button" href="http://download-verse.oss-cn-beijing.aliyuncs.com/DV101_Windows_2.4.3.1.rar">客户端下载</a>
                </div>
              </div>
              <div v-show="isShowTip == true" class="client-tip">{{ message }}</div>
            </div>
          </div>
          <div class="video-bg" id="video-bg">
            <video
              class="intro-video"
              :src="videoUrl"
              autoplay="autoplay"
              loop="loop"
              preload="auto"
              muted="muted">
            </video>
          </div>
        </div>

        <!-- 电脑配置 -->
        <div class="system">
          <div class="system-box wrapper">
            <div class="system-info">
              <h3>系统需求</h3>
              <div class="config">
                <div class="advice-config">
                  <p>建议配置</p>
                  <p>需要64位处理器和操作系统</p>
                  <p>操作系统：Window 10</p>
                  <p>处理器：7代酷睿i5 2.4GHz</p>
                  <p>内存：16 GB RAM</p>
                  <p>显卡：GTX1060</p>
                  <p>DirectX：第11版</p>
                  <p>硬盘：512G</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <Footer></Footer>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import SideBar from '@/components/SideBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Client',
  data: function() {
    return {
      videoUrl: require('../assets/video/dv101.mp4'),
      isShowTip: false,
      message: '敬请期待'
    }
  },
  components: {
    Header,
    Footer,
    SideBar
  },
  mounted() {
  },
  methods: {
    downClient(){
      var that = this
      that.isShowTip = true
      setTimeout(function(){
        that.isShowTip = false
      }, 3000)
    }
  }
}
</script>
